export const specialSymbols = "!@#$%^&*";

export const validateUserPassword = (password) => {
  const test = {
    isValid: false,
    errors: [],
  };

  // const itHasSpecialCharacter = new RegExp(`(?=.*[${specialSymbols}])`).test(
  //   password
  // );

  // if (!itHasSpecialCharacter) {
  //   test.errors.push("passwordNotHasSY");
  // }

  const itHasLetter = /[a-zA-Z]/.test(password);
  if (!itHasLetter) {
    test.errors.push("passwordNotHasLetter");
  }

  const itHasNumber = /[0-9]/.test(password);
  if (!itHasNumber) {
    test.errors.push("passwordNotHasNumber");
  }

  const itLong = password.length >= 8;
  if (!itLong) {
    test.errors.push("passwordTooShort");
  }

  return {
    ...test,
    isValid: !test.errors.length,
  };
};

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const generatePasswordForUser = () => {
  let generatedPassword;
  const randomLength = getRandomNumber(6, 7);
  const minNumber = 10 ** (randomLength - 1);
  generatedPassword = getRandomNumber(minNumber, minNumber * 10 - 1).toString();
  // const randomLengthCapitalLetters = getRandomNumber(1, 3);

  // for (let i = 1; i <= randomLengthCapitalLetters; i += 1) {
  //   const randomIndex = getRandomNumber(0, generatedPassword.length);
  //   const randomCL = String.fromCharCode(getRandomNumber(97, 122));
  //   generatedPassword =
  //     generatedPassword.slice(0, randomIndex) +
  //     randomCL +
  //     generatedPassword.slice(randomIndex);
  // }

  const randomLetter =
    getRandomNumber(1, 3) === 1
      ? String.fromCharCode(getRandomNumber(65, 91)) + String.fromCharCode(getRandomNumber(97, 123))
      : String.fromCharCode(getRandomNumber(97, 123)) + String.fromCharCode(getRandomNumber(65, 91));
  const randomIndex = getRandomNumber(0, generatedPassword.length);

  generatedPassword =
    generatedPassword.slice(0, randomIndex) +
    randomLetter +
    generatedPassword.slice(randomIndex);
  return generatedPassword;
};

// console.log(generatePasswordForUser());
